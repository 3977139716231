import React from 'react';
import { css } from '@emotion/core';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { container, title } from '../styles';

const thumbnail = css`
    padding: 0;
    border: none;

    > div {
        transform: scale(1);
        transition: transform 0.3s;
    }

    &:hover {
        cursor: pointer;

        > div {
            transform: scale(1.15);
        }
    }
`;

const GalleryPage = () => {
    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
    const data = useStaticQuery(graphql`
        query GalleryQuery {
            allFile(filter: { relativePath: { regex: "/gallery/" } }) {
                edges {
                    node {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                }
            }
        }
    `);

    const photos = data.allFile.edges.map(({ node }) => ({
        ...node.childImageSharp.fluid,
        width: node.childImageSharp.fluid.presentationWidth,
        height: node.childImageSharp.fluid.presentationHeight,
    }));

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <Layout>
            <SEO title="Gallery" />
            <div css={container} style={{ maxWidth: '1600px' }}>
                <h1 css={title}>Gallery</h1>
                <Gallery
                    photos={photos}
                    direction="column"
                    margin={4}
                    onClick={openLightbox}
                    renderImage={({ photo, top, left, margin, key, onClick, index }) => (
                        <button
                            css={thumbnail}
                            type="button"
                            key={key}
                            onClick={event => onClick(event, { index, photo })}
                            style={{
                                position: 'absolute',
                                top: `${top}px`,
                                left: `${left}px`,
                                margin: `${margin}px`,
                                width: `${photo.width}px`,
                                height: `${photo.height}px`,
                                overflow: 'hidden',
                            }}
                        >
                            <div>
                                <Img fluid={photo} />
                            </div>
                        </button>
                    )}
                />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title,
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </Layout>
    );
};

export default GalleryPage;
